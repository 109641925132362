var $activeClass = 'is-active',
	$inactiveClass = 'is-inactive',
	$openClass = 'is-open',
	$shortClass = 'is-short',
	$currentClass = 'is-current',
	returnScroll, timeout, pointer,
	urlHash = location.hash,
	path = location.pathname;

$('.js-flow').on('beforeChange', function() {
	guideView('.js-guide');
});
$(document).ready(function() {
	if (urlHash && path === '/how-to/group/sej.html') {
		$('body,html').stop().scrollTop(0);
	}
});

/* ----------------
	accordion
	シンプルアコーディオン
	----------------- */
$('.js-accordion').on('click', function() {
	var $targetParent = $(this).parent();
	simpleAccordion($targetParent);
});

/* ----------------
	accordion
	シンプルアコーディオン faq
	----------------- */
$('.js-faq_accordion').on('click', function() {
	var $targetParent = $(this).parent();
	faqAccordion($targetParent);
});

/* ----------------
	accordion
	階層構造アコーディオン
	----------------- */
$('.js-list_accordion').on('click', function() {
	var $this = $(this);
	hierarchyAccordion($this);
});

// /entry/,/introduction/アコーディオン
if ($('.js-accordion-btn').length > 0) {
	$('.js-accordion-btn').on('click', function() {
		var $thisParent = $(this).parent(),
			$btn = $thisParent.find('.accordion-btn'),
			$btnText = $thisParent.find('.accrodion-btn__text'),
			$target = $thisParent.find('.js-hide__target'),
			_targetHeight = $target.find('.hide-target__inner').outerHeight();

		if ($btn.hasClass($openClass)) {
			$btn.removeClass($openClass);
			if ($btn.attr('data-default') !== undefined) {
				$btnText.text($btn.attr('data-default'));
			} else {
				$btnText.text('もっと見る');
			}

			$target.removeClass($openClass).css('height', 0);
		} else {
			$btn.addClass($openClass);
			$btnText.text('閉じる');
			$target.addClass($openClass).css('height', _targetHeight);
			setTimeout(function() {
				$target.css('height', 'auto');
			}, 300);
		}
	});

	var resetAccordion = function() {
		$('.js-accordion-btn').each(function() {
			var $thisParent = $(this).parent(),
				$btn = $thisParent.find('.accordion-btn'),
				$btnText = $thisParent.find('.accrodion-btn__text'),
				$target = $thisParent.find('.js-hide__target');

			if (!$btn.hasClass($openClass)) {
				if (parseInt($target.css('height')) >= 0) {
					$target.css('height', '');
				}
			}
		});
	};

	var resizeHandlerAccordion = function() {
		if (window.matchMedia('(min-width: 769px)').matches) {
			resetAccordion();
		}
	};
	window.addEventListener('resize', resizeHandlerAccordion);
}

/* ----------------
	tab
	ページ内タブ切り替え
	----------------- */
//タブ上部のメニューから遷移
$('.tab-list--top .js-tab_item a[href^="#"]').on('click', function(e) {
	e.preventDefault();
	var targetId = $(this).attr('href').replace('#', '');

	//カルーセルがある場合、初期位置にセットし直す
	if ($('.js-flow')[0]) {
		slickUnset();
	}

	tabOpen(targetId);
	if ($('.js-reload')[0]) {
		campaignHeightControl();
	}
});

//タブ下部のメニューから遷移
// tab2-content  tab2  #js-tab2_anchor
$('.tab-list--bottom .js-tab_item a[href^="#"]').on('click', function(e) {
	e.preventDefault();
	var targetId = $(this).attr('href').replace('#', ''),
		_headerHeight = $('#js-header').outerHeight(),
		_Position = 0,
		targetHash  = '#' + targetId;

	if ($(targetHash).hasClass('tab-content')) {
		_Position = $('#js-tab_anchor').offset().top;
	} else if ($(targetHash).hasClass('tab2-content')) {
		_Position = $('#js-tab2_anchor').offset().top;
	} else if ($(targetHash).hasClass('tab3-content')) {
		_Position = $('#js-tab3_anchor').offset().top;
	}

	//カルーセルがある場合、初期位置にセットし直す
	if ($('.js-flow')[0]) {
		slickUnset();
	}



	if ($('.js-reload')[0]) {
		campaignHeightControl();
	}

	if ($(window).width() > 768) {
		_Position -= _headerHeight;
	}

	//タブ内のトップへ移動
	$('html,body').animate({scrollTop: _Position}, 900);

	tabOpen(targetId);
});

/* ----------------
	tab-guide
	タブガイドの表示非表示
	----------------- */
$('.tab-list').on('touchmove', function() {
	guideView('.js-tab_guide');
});
$('.js-tab_item a').on('click', function() {
	guideView('.js-tab_guide');
});

/* ----------------
	pcHeader
	----------------- */
//メガドロップメニュー表示
$('.js-header_navi').on('click', function() {
	pointer = '#' + $(this).data('nav');
	dropMenu(pointer);
});

//メガドロップメニュー非表示
$(document).on('click', function(e) {
	var $target = $(e.target);

	if ($target.closest('.js-header_navi').length === 0 && $target.closest('.js-header_drop_menu').length === 0) {
		clearTimeout(timeout);
		timeout = setTimeout(function() {
			$(pointer).removeClass($activeClass);
			$(pointer).hide();
		}, 0);
	} else {
		clearTimeout(timeout);
	}
});

/* ----------------
	spHeader
	----------------- */
//グループメニューの表示
$('#js-haeder_group').on('click', function() {
	var headerHight = ($('#js-header_sp').outerHeight());

	if ($('#js-header_menu').hasClass($activeClass)) {
		removeHeaderMenu();
	}

	fixedGroupMenu(headerHight);
});

//グループメニューの非表示
$('#js-haeder_group_close').on('click', function() {
	var headerHight = ($('#js-header_sp').outerHeight());
	removeGroupMenu(headerHight);
});

//ハンバーガーメニューの表示/非表示
$('#js-menu').on('click', function() {
	var _targetHeight = $('#js-header_menu').find('.header-menu__list').outerHeight(),
		headerHeight = $('#js-header_sp').outerHeight(),
		scrollTop = $(window).scrollTop(),
		windowWidth = window.innerWidth,
		mainScroll = headerHeight - scrollTop,
		$btnmenu = $(this).find('.btn-menu'),
		mainAddClass = {
			top: mainScroll,
			width: windowWidth
		};

	if (!$btnmenu.hasClass($activeClass)) {
		$('#js-overlay').addClass($activeClass);
		$('#js-main').addClass($inactiveClass).css(mainAddClass);
		$('#js-menu_lead').text('閉じる');
		returnScroll = scrollTop;
		if (scrollTop === 0) {
			$btnmenu.addClass($activeClass);
			$('#js-header_menu').addClass($activeClass).css({
				'height': _targetHeight,
				'top': headerHeight
			});
			$('#js-header_sp').addClass($activeClass);
		} else {
			$btnmenu.addClass($activeClass);
			$('#js-header_menu').addClass($activeClass).css('top', headerHeight - 10);
			$('#js-header_sp').addClass($activeClass).addClass($shortClass).css('top', -10);
			$('#js-menu').addClass($activeClass).css('top', 0);
		}
		if ($('#js-groupmenu').hasClass($activeClass)) {
			$('#js-groupmenu').removeClass($activeClass);
			setTimeout(function() {
				$('#js-haeder_group').removeClass($inactiveClass);
				$('#js-haeder_group_close').removeClass($activeClass);
				$('#js-groupmenu').slideUp();
			}, 100);
		}
	} else {
		removeHeaderMenu();
		$(window).scrollTop(returnScroll);
		$('#js-main').css({
			'width': 'auto',
			'top': 'auto'
		});
	}
});

//ハンバーガーメニューの非表示
$('#js-menu_close').on('click', function() {
	removeHeaderMenu();
	$('#js-main').css({
		'width': 'auto',
		'top': 'auto'
	});
});


/* ----------------
	overlay
	overlayクリック時にactive解除
	----------------- */
$('#js-overlay').on('click', function() {
	var headerHeight = $('#js-header_sp').outerHeight();

	$('#js-overlay').removeClass($activeClass);

	if ($('#js-header_menu').hasClass($activeClass)) {
		removeHeaderMenu();
	} else if ($('#js-groupmenu').hasClass($activeClass)) {
		removeGroupMenu(headerHeight);
	}
});

/* ----------------
	windowScroll
	----------------- */
$(window).on('scroll', function() {
	if ($('#js-totop').hasClass($activeClass)) {
		$('#js-totop').fadeOut().removeClass($activeClass);
	}
	var scrollTop = $(window).scrollTop(),
		scrollHeight = $(document).height(),
		scrollPosition = $(window).height() + $(window).scrollTop(),
		windowWidth = window.innerWidth,
		footerHeight = $('footer').innerHeight(),
		toTopPosition;
	//PC表示時のヘッダーメニュー表示切り替え
	if (windowWidth > 768) {
		let headerHight = $('#js-header').outerHeight();
		if (scrollTop > 10) {
			shortHeader();
			$('#js-main').css('padding-top', headerHight);
		} else {
			inShortHeader();
			$('#js-main').css('padding-top', 0);
		}
	}

	//SP表示時のヘッダーメニュー表示切り替え
	if (windowWidth < 768) {
		if (scrollTop > 10 || scrollTop <= 10 && $('#js-header_sp').hasClass($shortClass)) {
			shortHeader();
			$('#js-menu').css('top', 0);
			$('#js-menu').css('position', 'fixed');
		} else {
			inShortHeader();
			$('#js-menu').css('top', 10);
			$('#js-menu').css('position', 'absolute');
		}
	}

	//toTop表示
	clearTimeout(timeout);
	toTopPosition = windowWidth - 10;
	//表示枠の高さ調整、totopの位置調整
	if (windowWidth > 768) {
		toTopPosition = windowWidth - 80;
	}
	// /cp/sej2204.html用totop表示位置調整
	var path = location.pathname;
	// var floating_height = ($('.top_floating').css('display') === 'none') ? 0 : $('.top_floating').innerHeight();
	var floating_height = 0;
	var $floating = $('.top_floating');
	// .top_floating が存在していて、かつ表示されていれば高さを取得
	if ($floating.length && $floating.css('display') !== 'none') {
		floating_height = $floating.innerHeight();
	}
	
	// if ($('.top_floating').css('display') === 'none') {
	// 	aaa = $('.top_floating').innerHeight();
	// }
	if (path === '/cp/sej2204.html') {
		var bottomPosition = 150;
		if (windowWidth > 768) {
			bottomPosition = 180;
		}
		if (scrollHeight - scrollPosition + bottomPosition - 30 <= footerHeight) {
			$('.totop').css ({
				'bottom': footerHeight + 40,
				'left': toTopPosition,
				'position': 'absolute',
				'margin-bottom': '0',
			});
		} else {
			$('.totop').css ({
				'bottom': bottomPosition + 'px',
				'left': toTopPosition,
				'position': 'fixed',
				'margin-bottom': '',
			});
		}
	} else if (scrollHeight - scrollPosition <= footerHeight) {
		$('.totop').css ({
			'bottom': footerHeight + 40,
			'left': toTopPosition,
			'position': 'absolute',
			'margin-bottom': '0',
		});
	} else {
		$('.totop').css ({
			'bottom': (floating_height + 50) + 'px',
			'left': toTopPosition,
			'position': 'fixed',
			'margin-bottom': '',
		});
	}
	timeout = setTimeout(function() {
		if (scrollTop !== 0) {
			$('#js-totop').fadeIn().addClass($activeClass);
		}
	}, 300);

	//レイジースクロール
	if ($('.js-img-disp').length) {
		let headerHight = $('#js-header').outerHeight();
		$('.js-img-disp').each(function() {
			var result;
			if (windowWidth > 768) {
				result = $(this).offset().top - $(window).height() + headerHight;
			} else {
				result = $(this).offset().top - $(window).height() + $('#js-header_sp').height() + 50;
			}
			if (scrollTop > result) {
				$(this).css('opacity', '1');
				$(this).css('transform', 'translateY(0)');
			} else {
				$(this).css('opacity', '0');
			}
		});
	}
});

/* ----------------
	windowLoad
	----------------- */
$(window).on('load', function() {
	// ファビコン
	ins_favicon('/material/img/common/favicon.ico', 'shortcut icon');
	ins_favicon('/material/img/common/apple-touch-icon-precomposed.png', 'apple-touch-icon-precomposed');

	// ヘッダーのカレント表示
	pcHeaderCurrent();
	spHeaderCurrent();

	// お知らせ・ニュースリリースのカレント表示
	if ($('#js-information')) {
		infomationCurrent();
	}

	//タブの初期表示
	if ($('.js-tab')[0]) {
		// 初期化
		$('.js-tab_item a[href^="#"]').filter('.' + $activeClass).each(function() {
			var DefaultTargetId = $(this).attr('href').replace('#', '');
			tabOpen(DefaultTargetId);
		});
	}

	//カルーセルの初期表示
	slickInitialize();
	if ($('.js-flow').length) {
		$('.js-flow').each(function() {
			var _slickTrack = $(this).find('.slick-track'),
				_slickHeight = _slickTrack.outerHeight();

			_slickTrack.css('height', _slickHeight);
		});
	}
	if ($('.js-mynumbercardpoint__slider.mynp_01').length) {
		$('.js-mynumbercardpoint__slider.mynp_01').each(function() {
			var _slickTrack = $(this).find('.slick-track'),
				_slickHeight = _slickTrack.outerHeight();

			_slickTrack.css('height', _slickHeight);
		});
	}
	if ($('.js-mynumbercardpoint__slider.mynp_02').length) {
		$('.js-mynumbercardpoint__slider.mynp_02').each(function() {
			var _slickTrack = $(this).find('.slick-track'),
				_slickHeight = _slickTrack.outerHeight();

			_slickTrack.css('height', _slickHeight);
		});
	}

	//高さサイズ調整要素
	if ($('.js-adjustheight__item').length) {
		adjustheight();
	}

	//nanacoモバイルページ デバイス毎に処理
	var deviceType = window.navigator.userAgent.toLowerCase();
	if (deviceType.indexOf('android') > 0) {
		$('.js-android__item').each(function() {
			$(this).removeClass('js-android__item');
		});
	} else if (deviceType.indexOf('iphone') > 0) {
		$('.js-ios__item').each(function() {
			$(this).removeClass('js-ios__item');
		});
	} else {
		$('.js-pc__item').each(function() {
			$(this).removeClass('js-pc__item');
		});
	}

	//SPのみ電話発信を可能にする
	if (deviceType.indexOf('android') > 0 || deviceType.indexOf('iphone') > 0) {
		$('.js-cta-call___text').each(function() {
			$(this).addClass('call__link--sp-only');
		});
	}

	// how-to/group/sej.html用 表示リセット
	var path = location.pathname;
	var anchor = location.hash;
	if (path === '/how-to/group/sej.html') {
		radioContentsReset();
		if(anchor !== '') {
			var _headerHeight = $('#js-header').outerHeight(),
				_Position = $(anchor).offset().top - 50;
			if ($(window).width() > 768) {
				_Position -= _headerHeight;
			}
			$('html,body').animate({scrollTop: _Position}, 900);
		}
	}

	// フローティングDLボタンがない場合のチャットボット位置調整
	if ($('.under-display').length === 0) {
		$('#bedore-webagent-inner').addClass('under-display-close');
	} else {
		$('.totop').addClass('under-display-open');
		$('.footer').addClass('under-display-open');
	}
	setTimeout(() => {
		$('#bedore-webagent-inner').contents().on('click', function() {
			const chatboxHeight = $('#bedore-webagent-inner').attr('height');
			const num = Number(chatboxHeight.substr(0, chatboxHeight.indexOf('px')));
			if (num > 61) {
				$('.totop').css('z-index', '998');
			} else {
				$('.totop').css('z-index', '10001');
			}
		});
	}, 900);
});

/* ----------------
	windowResize
	----------------- */
$(window).on('resize orientationchange', function() {
	var headerHight,
		windowWidth = window.innerWidth,
		toTopPosition = windowWidth - 10;

	//表示枠の高さ調整、totopの位置調整
	if (windowWidth > 768 && $('#js-header').hasClass('is-short')) {
		headerHight = ($('#js-header').outerHeight());
		toTopPosition = windowWidth - 80;
	} else {
		headerHight = 0;
	}

	$('.main').css('padding-top', headerHight);
	$('.totop').css('left', toTopPosition);

	if (windowWidth < 768) {
		headerHight = ($('#js-header_sp').outerHeight() - 10);
		$('#js-menu').css('height', headerHight);
	}

	if ($('.js-adjustheight__item').length) {
		adjustheight();
	}
});

/* ----------------
	バナースライダー
	----------------- */
$('.js-banner__slider').each(function() {
	var $this = $(this);
	if ($this.find('li').length === 2) {
		$this.slick({
			variableWidth: true,
			dots: true,
			autoplay: true,
			autoplaySpeed: 6000,
			speed: 800,
			responsive: [{
				breakpoint: 767,
				settings: {
					slidesToShow: 1
				}
			}]
		});
	} else {
		$this.slick({
			variableWidth: true,
			dots: true,
			autoplay: true,
			autoplaySpeed: 6000,
			centerMode: true,
			centerPadding: '30px',
			speed: 800,
			responsive: [{
				breakpoint: 767,
				settings: {
					slidesToShow: 1
				}
			}]
		});
	}
});

/* ----------------
	ポイントアップバナースライダー
	----------------- */
$('.js-pointcp__slider').each(function() {
	var $this = $(this);
	if ($this.find('li').length === 2) {
		$this.slick({
			variableWidth: true,
			dots: true,
			autoplay: true,
			autoplaySpeed: 6000,
			speed: 800,
			responsive: [{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					centerMode: true
				}
			}]
		});
	} else {
		$this.slick({
			variableWidth: true,
			dots: true,
			autoplay: true,
			autoplaySpeed: 6000,
			centerMode: true,
			centerPadding: '30px',
			speed: 800,
			responsive: [{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					centerMode: true
				}
			}]
		});
	}
});
/* ----------------
	おすすめキャンペーンスライダー
	----------------- */
$('.js-pickup__slider').slick({
	slidesToShow: 4,
	autoplay: true,
	autoplaySpeed: 6000,
	speed: 800,
	responsive: [{
		breakpoint: 768,
		settings: {
			slidesToShow: 1,
			centerMode: true,
			centerPadding: '13%'
		}
	}]
});

/* ----------------
	トップスライダー
	----------------- */
$('.js-top__slider').slick({
	centerMode: true,
	slidesToShow: 1,
	infinite: true,
	dots: true,
	centerPadding: '165px',
	autoplay: true,
	autoplaySpeed: 6000,
	speed: 800,
	responsive: [{
		breakpoint: 768,
		settings: {
			centerPadding: '0px',
			infinite: true
		}
	}]
});

/* ----------------
	nanacoモバイルスライダー
	----------------- */
$('.js-mobile-page__slider').each(function() {
	var $this = $(this);

	if ($this.find('li').length > 3) {
		$this.slick({
			infinite: false,
			dots: false,
			arrows: true,
			variableWidth: true,
			responsive: [{
				breakpoint: 767,
				settings: {
					arrows: true,
					dots: false,
					variableWidth: true,
					slidesToShow: 1
				}
			}]
		});
	} else if ($this.find('li').length >= 2) {
		$this.slick({
			infinite: false,
			arrows: true,
			dots: false,
			variableWidth: true,
			draggable: false,
			responsive: [{
				breakpoint: 1080,
				settings: {
					dots: false,
					arrows: true,
					draggable: true,
					slidesToShow: $this.length
				}
			}, {
				breakpoint: 767,
				settings: {
					dots: false,
					arrows: false,
					draggable: true,
					variableWidth: true,
					slidesToShow: 1
				}
			}]
		});
	} else {
		$this.find('li').css('margin-left', 0);
	}
});

/* ----------------
	使えるお店ページポイントアップキャンペーンスライダー
	----------------- */
$('.js-al-pointcp__slider').slick({
	slidesToShow: 2,
	autoplay: true,
	autoplaySpeed: 6000,
	speed: 800,
	swipeToSlide: true,
	variableWidth: true,
	responsive: [{
		breakpoint: 768,
		settings: {
			slidesToShow: 1,
			centerMode: true,
			centerPadding: '13%'
		}
	}]
});

/* ----------------
	CP配下類似スライダー
	----------------- */
$('.js-cp-page__slider').each(function() {
	var $this = $(this);

	if ($this.find('li').length > 3) {
		$this.slick({
			infinite: false,
			dots: true,
			variableWidth: true,
			responsive: [{
				breakpoint: 767,
				settings: {
					dots: true,
					variableWidth: true,
					slidesToShow: 1
				}
			}]
		});
	} else if ($this.find('li').length >= 2) {
		$this.slick({
			infinite: false,
			arrows: false,
			dots: true,
			variableWidth: true,
			draggable: false,
			responsive: [{
				breakpoint: 1080,
				settings: {
					arrows: true,
					draggable: true,
					slidesToShow: $this.length
				}
			}, {
				breakpoint: 767,
				settings: {
					arrows: true,
					draggable: true,
					variableWidth: true,
					slidesToShow: 1
				}
			}]
		});
	} else {
		$this.find('li').css('margin-left', 0);
	}
});

/* ----------------
	campaign
	----------------- */
if ($('.js-reload')[0]) {
	$(window).on('load resize orientationchange', function() {
		campaignHeightControl();
	});
}

if ($('.js-minheight__controll')[0]) {
	$(window).on('load resize orientationchange', function() {
		minHeightControl();
	});
}

/* ----------------
	スムーススクロール
	----------------- */
$('.js-anchor').on('click', function(e) {
	e.preventDefault();
	smoothScroll(this);
});

/* ----------------
	toTop
	----------------- */
$('#js-totop').on('click', function() {
	toTop();
});

/* ----------------
	フローティングバナー非表示
	閉じるボタンクリック時に非表示
	----------------- */
$('#js-under-display-close').on('click', function() {
	$('.under-display').css('display', 'none');
	$('#bedore-webagent-inner').addClass('under-display-close');
	$('.totop').removeClass('under-display-open');
	$('.footer').removeClass('under-display-open');
});

$('.js-under-display-close').on('click', function() {
	$('.under-display').css('display', 'none');
	$('.download-link-wrap').css('display', 'none');
	$('#bedore-webagent-inner').addClass('under-display-close');
	$('.totop').removeClass('under-display-open');
	$('.footer').removeClass('under-display-open');
});

/* ----------------
	radio-option
	/how-to/group/sej.html の要素出し分け
	----------------- */
$('.js-option1_contents .js-radio-label').on('click', function(e) {
	radioContentsReset();
	document.getElementsByClassName('js-radio').item(2).checked = false;
	document.getElementsByClassName('js-radio').item(3).checked = false;
	document.getElementsByClassName('js-radio').item(4).checked = false;
	document.getElementsByClassName('js-radio').item(5).checked = false;
	document.getElementsByClassName('js-radio').item(6).checked = false;
	document.getElementsByClassName('js-radio').item(7).checked = false;
	document.getElementsByClassName('js-radio').item(8).checked = false;
	document.getElementsByClassName('js-radio').item(9).checked = false;
	setTimeout(function() {
		$('.js-option2_contents').removeClass('hide');
		$('.js-option2_contents').removeClass('show');
		$('.js-option2_contents').addClass('clear');
		$('.js-option3_contents').addClass('clear');
		$('.js-option4_contents').addClass('clear');
		$('.js-option3_contents').removeClass('show');
		$('.js-option4_contents').removeClass('show');
		$('.js-option5_contents').addClass('hide');
	}, 1);
	setTimeout(function() {
		$('.js-option2_contents').addClass('show');
	}, 200);
	setTimeout(function() {
		e.preventDefault();
		$('.js-option2_contents').removeClass('clear');
		$('.js-option3_contents').addClass('hide');
		$('.js-option4_contents').addClass('hide');
		smoothScroll_position('#anchor_option2');
	}, 300);
});

$('.js-option2_contents .js-radio-label').on('click', function(e) {
	let checkId = '';
	let noCheckId = '';
	let radio = document.getElementsByName('option2');
	radioContentsReset();
	document.getElementsByClassName('js-radio').item(4).checked = false;
	document.getElementsByClassName('js-radio').item(5).checked = false;
	document.getElementsByClassName('js-radio').item(6).checked = false;
	document.getElementsByClassName('js-radio').item(7).checked = false;
	document.getElementsByClassName('js-radio').item(8).checked = false;
	document.getElementsByClassName('js-radio').item(9).checked = false;
	setTimeout(function() {
		let len = radio.length;
		for (let i = 0; i < len; i++) {
			if (radio.item(i).checked) {
				checkId = radio.item(i).id;
			} else {
				noCheckId = radio.item(i).id;
			}
		}
		$('.js-' + noCheckId + '_contents').removeClass('show');
		$('.js-' + noCheckId + '_contents').addClass('clear');
	}, 1);
	setTimeout(function() {
		$('.js-' + checkId + '_contents').addClass('clear');
		$('.js-' + noCheckId + '_contents').addClass('hide');
		$('.js-' + checkId + '_contents').removeClass('hide');
	}, 210);
	setTimeout(function() {
		$('.js-' + checkId + '_contents').addClass('show');
		$('.js-' + checkId + '_contents').removeClass('clear');
		e.preventDefault();
		smoothScroll_position('#anchor_' + checkId);
	}, 300);
});

$('.js-option3_contents .js-radio-label').on('click', function(e) {
	let checkId = '';
	let radio = document.getElementsByName('option3');
	let radio2 = document.getElementsByName('option1');
	radioContentsReset();
	$('.js-option3_contents').removeClass('clear');
	$('.js-option5_contents').removeClass('hide');
	$('.js-option5_contents').addClass('clear');
	setTimeout(function() {
		let len = radio.length;
		for (let i = 0; i < len; i++) {
			if (radio.item(i).checked) {
				checkId = radio.item(i).id;
			}
		}
		if (radio2.item(1).checked) {
			$('.js-option5-1_contents').removeClass('hide');
			$('.js-num1').addClass('active-num');
			$('.js-arrow1_contents').removeClass('hide');
		}
		if (checkId === 'option5') {
			$('.js-option5-5_contents').removeClass('hide');
			$('.js-num5').addClass('active-num');
		} else if (checkId === 'option6') {
			$('.js-option5-4_2_contents').removeClass('hide');
			$('.js-num4_2').addClass('active-num');
			$('.js-arrow3_contents').removeClass('hide');
			$('.js-option5-5_contents').removeClass('hide');
			$('.js-num5').addClass('active-num');
		} else if (checkId === 'option7') {
			$('.js-option5-4_contents').removeClass('hide');
			$('.js-num4').addClass('active-num');
			$('.js-arrow3_contents').removeClass('hide');
			$('.js-option5-5_contents').removeClass('hide');
			$('.js-num5').addClass('active-num');
		}
	}, 1);
	setTimeout(function() {
		$('.js-option5_contents').addClass('show');
	}, 200);
	setTimeout(function() {
		e.preventDefault();
		smoothScroll_position('#anchor_option5');
	}, 100);
});

$('.js-option4_contents .js-radio-label').on('click', function(e) {
	let checkId = '';
	let radio = document.getElementsByName('option4');
	let radio2 = document.getElementsByName('option1');
	radioContentsReset();
	$('.js-option4_contents').removeClass('clear');
	$('.js-option5_contents').removeClass('hide');
	$('.js-option5_contents').addClass('clear');
	setTimeout(function() {
		let len = radio.length;
		for (let i = 0; i < len; i++) {
			if (radio.item(i).checked) {
				checkId = radio.item(i).id;
			}
		}
		if (radio2.item(1).checked) {
			$('.js-option5-1_contents').removeClass('hide');
			$('.js-num1').addClass('active-num');
			$('.js-arrow1_contents').removeClass('hide');
		}
		if (checkId === 'option8') {
			$('.js-option5_contents').removeClass('hide');
			$('.js-option5-2_contents').removeClass('hide');
			$('.js-num2').addClass('active-num');
			$('.js-arrow2_contents').removeClass('hide');
			$('.js-option5-4_2_contents').removeClass('hide');
			$('.js-num4_2').addClass('active-num');
			$('.js-arrow3_contents').removeClass('hide');
			$('.js-option5-5_contents').removeClass('hide');
			$('.js-num5').addClass('active-num');
		} else {
			$('.js-option5_contents').removeClass('hide');
			$('.js-option5-3_contents').removeClass('hide');
			$('.js-num3').addClass('active-num');
			$('.js-arrow2_contents').removeClass('hide');
			$('.js-option5-4_contents').removeClass('hide');
			$('.js-num4').addClass('active-num');
			$('.js-arrow3_contents').removeClass('hide');
			$('.js-option5-5_contents').removeClass('hide');
			$('.js-num5').addClass('active-num');
		}
	}, 1);
	setTimeout(function() {
		$('.js-option5_contents').addClass('show');
	}, 200);
	setTimeout(function() {
		e.preventDefault();
		smoothScroll_position('#anchor_option5');
	}, 300);
});

/* ----------------
	radioContentsReset
	/how-to/group/sej.html の表示要素リセット
	----------------- */
var radioContentsReset = function() {
	$('.js-option5_contents').addClass('active');
	$('.js-option5_contents').addClass('hide');
	$('.js-option5_contents').addClass('clear');
	$('.js-option5_contents').removeClass('show');
	$('.js-option5-1_contents').addClass('hide');
	$('.js-option5-2_contents').addClass('hide');
	$('.js-option5-3_contents').addClass('hide');
	$('.js-option5-4_contents').addClass('hide');
	$('.js-option5-4_2_contents').addClass('hide');
	$('.js-option5-5_contents').addClass('hide');
	$('.js-num1').removeClass('active-num');
	$('.js-num2').removeClass('active-num');
	$('.js-num3').removeClass('active-num');
	$('.js-num4').removeClass('active-num');
	$('.js-num4_2').removeClass('active-num');
	$('.js-num5').removeClass('active-num');
	$('.js-arrow1_contents').addClass('hide');
	$('.js-arrow2_contents').addClass('hide');
	$('.js-arrow3_contents').addClass('hide');
};

/* ----------------
	スムーススクロール
	----------------- */
var smoothScroll = function(anchor) {
	var $this = $(anchor),
		$ID = $this.attr('href'),
		_headerHeight = $('#js-header').outerHeight(),
		_Position = $($ID).offset().top;

	if ($(window).width() > 768) {
		_Position -= _headerHeight;
	}

	$('html,body').animate({scrollTop: _Position}, 900);
};

var smoothScroll_position = function(anchor) {
	var $ID = $(anchor),
		_headerHeight = $('#js-header').outerHeight(),
		_Position = $($ID).offset().top - 100;

	if ($(window).width() > 768) {
		_Position -= _headerHeight;
	}

	$('html,body').animate({scrollTop: _Position}, 500);
};

/* ----------------
	toTop
	----------------- */
var toTop = function() {
	$('html,body').animate({
		scrollTop: 0
	}, 900);
};

/* ----------------
	shortHeader
	----------------- */
var shortHeader = function() {
	$('#main').addClass('is-fixed');
	$('#js-header').addClass($shortClass);
	$('#js-header').find('.header-logo').addClass($shortClass);
	$('#js-header').find('.header-inner').addClass($shortClass);
	$('#js-header').find('.header-navi').addClass($shortClass);
	$('#js-header').find('.header-navi__link').addClass($shortClass);
	$('#js-header').find('.header-navi__logo').addClass($shortClass);
	$('#js-header').find('.header-navi__item').addClass($shortClass);
	$('#js-header').find('.header-main__wrap').addClass($shortClass);
	$('#js-global_header').find('.global-list__item').addClass($shortClass);
	$('#js-global_header').find('.global-list__link').addClass($shortClass);
	$('#js-global_header').find('.global-list__lead').addClass($shortClass);
	$('#js-global_header').find('.global-title__lead').addClass($shortClass).html('nanaco関連サービス');
};

/* ----------------
	inShortHeader
	----------------- */
var inShortHeader = function() {
	$('#main').removeClass('is-fixed');
	$('#js-header').removeClass($shortClass);
	$('#js-header').find('.header-logo').removeClass($shortClass);
	$('#js-header').find('.header-inner').removeClass($shortClass);
	$('#js-header').find('.header-navi').removeClass($shortClass);
	$('#js-header').find('.header-navi__link').removeClass($shortClass);
	$('#js-header').find('.header-navi__logo').removeClass($shortClass);
	$('#js-header').find('.header-main__wrap').removeClass($shortClass);
	$('#js-header').find('.header-navi__item').removeClass($shortClass);
	$('#js-global_header').find('.global-list__link').removeClass($shortClass);
	$('#js-global_header').find('.global-list__item').removeClass($shortClass);
	$('#js-global_header').find('.global-list__lead').removeClass($shortClass);
	$('#js-global_header').find('.global-title__lead').removeClass($shortClass).html('nanaco<br>関連サービス');
};

/* ----------------
	fixedHeaderMenu
	----------------- */
var fixedHeaderMenu = function() {
	$('#js-overlay').addClass($activeClass);
	$('#js-main').addClass($inactiveClass).css(mainAddClass);
	$('#js-menu_lead').text('閉じる');
};

/* ----------------
	removeHeaderMenu
	----------------- */
var removeHeaderMenu = function() {
	$('#js-menu').find('.btn-menu').removeClass($activeClass);
	$('#js-header_menu').removeClass($activeClass).css('height', 0);
	$('#js-header_sp').removeClass($activeClass).removeClass($shortClass).css('top', 0);
	$('#js-overlay').removeClass($activeClass);
	$('#js-menu').removeClass($activeClass);
	$('#js-main').removeClass($inactiveClass);
	$('#js-menu_lead').text('メニュー');
};

/* ----------------
	fixedGroupMenu
	----------------- */
var fixedGroupMenu = function(headerHight) {
	$('#js-haeder_group').addClass($inactiveClass);
	$('#js-haeder_group_close').addClass($activeClass);
	$('#js-groupmenu').addClass($activeClass);
	$('#js-main').addClass($inactiveClass).css({
		'top': headerHight,
		'padding-top': 0
	});
	$('#js-groupmenu').slideDown();
	$('#js-overlay').addClass($activeClass);
	$('#js-header_sp').addClass($activeClass);
};

/* ----------------
	removeGroupMenu
	----------------- */
var removeGroupMenu = function(headerHeight) {
	$('#js-groupmenu').slideUp();
	$('#js-groupmenu').removeClass($activeClass);
	$('#js-haeder_group').removeClass($inactiveClass);
	$('#js-haeder_group_close').removeClass($activeClass);
	$('#js-overlay').removeClass($activeClass);
	$('#js-main').removeClass($inactiveClass).css('padding-top', headerHeight);
};

/* ----------------
	dropMenu
	----------------- */
var dropMenu = function(target) {
	if ($('.js-header_drop_menu').hasClass($activeClass) && !$(target).hasClass($activeClass)) {
		$('.js-header_drop_menu').removeClass($activeClass);
		$('.js-header_drop_menu').hide();
	}

	if (!$(target).hasClass($activeClass)) {
		var _headerHeight = $('#js-header').outerHeight();
		var _windowHeight = window.outerHeight;
		var _max_height = _windowHeight - _headerHeight - 100;
		$('.js-header_drop_menu').css('max-height', _max_height);
		$('.js-header_drop_menu .' + $activeClass).hide();
		$('.js-header_drop_menu .' + $activeClass).removeClass($activeClass);
		$(target).addClass($activeClass);
		$(target).fadeIn();
	} else if ($(target).hasClass($activeClass)) {
		$(target).removeClass($activeClass);
		$(target).hide();
	}
};

/* ----------------
	slickInitialize
	----------------- */
var slickInitialize = function() {
	var $flow = $('.js-flow');
	$flow.each(function() {
		var $this = $(this);

		if ($this.find('li').length > 3) {
			$this.slick({
				infinite: false,
				dots: true,
				variableWidth: true,
				responsive: [{
					breakpoint: 767,
					settings: {
						dots: false,
						variableWidth: true,
						slidesToShow: 1
					}
				}]
			});
		} else if ($this.find('li').length >= 2) {
			$this.slick({
				infinite: false,
				arrows: false,
				dots: false,
				variableWidth: true,
				draggable: false,
				responsive: [{
					breakpoint: 1080,
					settings: {
						arrows: true,
						draggable: true,
						slidesToShow: $this.length
					}
				}, {
					breakpoint: 767,
					settings: {
						arrows: true,
						draggable: true,
						variableWidth: true,
						slidesToShow: 1
					}
				}]
			});
		} else {
			$this.find('li').css('margin-left', 0);
		}
	});
};
var pointcpSlickInitialize = function() {
	var $flow = $('.js-al-pointcp__slider');
	$flow.each(function() {
		var $this = $(this);

		if ($this.find('li').length > 6) {
			$this.slick({
				slidesToShow: 2,
				autoplay: true,
				autoplaySpeed: 6000,
				speed: 800,
				swipeToSlide: true,
				variableWidth: true,
				responsive: [{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						centerMode: true,
						centerPadding: '13%'
					}
				}]
			});
		} else if ($this.find('li').length > 3) {
			$this.slick({
				infinite: false,
				arrows: false,
				variableWidth: true,
				responsive: [{
					breakpoint: 768,
					settings: {
						infinite: true,
						slidesToShow: 1,
						centerMode: true,
						centerPadding: '13%',
						arrows: true,
						autoplay: true,
						autoplaySpeed: 6000,
						speed: 800,
						swipeToSlide: true,
						variableWidth: true,
					}
				}]
			});
		} else if ($this.find('li').length >= 2) {
			$this.slick({
				infinite: false,
				arrows: false,
				variableWidth: true,
			});
		} else {
			$this.find('li').css('margin-left', 0);
		}
	});
};

var pointcpLogoSlickInitialize = function() {
	var $flow = $('.js-pointcp-logo__slider');
	$flow.each(function() {
		var $this = $(this);

		if ($this.find('li').length > 6) {
			$this.slick({
				slidesToShow: 5,
				autoplay: true,
				autoplaySpeed: 6000,
				speed: 800,
				swipeToSlide: true,
				variableWidth: true,
				responsive: [{
					breakpoint: 768,
					settings: {
						slidesToShow: 3
					}
				}]
			});
		} else if ($this.find('li').length > 3) {
			$this.slick({
				infinite: false,
				arrows: false,
				variableWidth: true,
				responsive: [{
					breakpoint: 768,
					settings: {
						infinite: true,
						slidesToShow: 3,
						arrows: true,
						autoplay: true,
						autoplaySpeed: 6000,
						speed: 800,
						swipeToSlide: true,
						variableWidth: true,
					}
				}]
			});
		} else if ($this.find('li').length >= 2) {
			$this.slick({
				infinite: false,
				arrows: false,
				variableWidth: true,
			});
		} else {
			$this.find('li').css('margin-left', 0);
		}
	});
};

$('.js-mynumbercardpoint__slider').slick({
	slidesToShow: 3,
	autoplay: false,
	speed: 800,
	swipeToSlide: true,
	infinite: false,
	dots: true,
	variableWidth: true,
	responsive: [{
		breakpoint: 768,
		settings: {
			slidesToShow: 1,
			centerMode: true,

			variableWidth: true,
		}
	}]
});


/* ----------------
	slickUnset
	----------------- */
var slickUnset = function() {
	$('.js-flow.slick-initialized').slick('unslick');
	$('.js-flow').hide();
	slickInitialize();
	$('.js-flow').fadeIn();
	setTimeout(function() {
		if ($('.js-flow').length) {
			$('.js-flow').each(function() {
				var _slickTrack = $(this).find('.slick-track'),
					_slickHeight = _slickTrack.outerHeight();

				_slickTrack.css('height', _slickHeight);
			});
		}
	}, 300);
};
var pointcpSlickUnset = function() {
	$('.js-al-pointcp__slider.slick-initialized').slick('unslick');
	$('.js-al-pointcp__slider').hide();
	pointcpSlickInitialize();
	$('.js-al-pointcp__slider').fadeIn();
	setTimeout(function() {
		if ($('.js-al-pointcp__slider').length) {
			$('.js-al-pointcp__slider').each(function() {
				var _slickTrack = $(this).find('.slick-track'),
					_slickHeight = _slickTrack.outerHeight();

				_slickTrack.css('height', _slickHeight);
			});
		}
	}, 300);
};

/* ----------------
	guideView
	----------------- */
var guideView = function(guideClass) {
	setTimeout(function() {
		$(guideClass).fadeOut(300);
	}, 1000);
};

/* ----------------
	simpleAccordion
	----------------- */
var simpleAccordion = function($targetParent) {
	var $btn = $targetParent.find('.accordion-btn'),
		$btnText = $targetParent.find('.accrodion-btn__text'),
		$target = $targetParent.find('.js-hide__target'),
		_targetHeight = $target.find('.hide-target__inner').outerHeight();

	if ($btn.hasClass($openClass)) {
		$btn.removeClass($openClass);
		if ($btn.attr('data-default') === '') {
			$btnText.text($btn.attr('data-default'));
		} else {
			$btnText.text('もっと見る');
		}
		$target.removeClass($openClass).css('height', 0);
	} else {
		$btn.addClass($openClass);
		if ($btn.attr('data-default') === '') {
			$btnText.text($btn.attr('data-default'));
		} else {
			$btnText.text('閉じる');
		}
		$target.addClass($openClass).css('height', _targetHeight);
		setTimeout(function() {
			$target.css('height', 'auto');
		}, 300);
	}
};

/* ----------------
	faqAccordion
----------------- */
var faqAccordion = function($targetParent) {
	var $accordion = $targetParent.find('.js-faq_accordion'),
		$target = $targetParent.find('.js-hide__target'),
		targetHeight = $targetParent.find('.js-hide_target_inner').innerHeight();

	if ($accordion.hasClass($openClass)) {
		$accordion.removeClass($openClass);
		$target.removeClass($openClass).css('height', 0);
	} else {
		$accordion.addClass($openClass);
		if ($target.hasClass('hide-target--wide')) {
			$target.addClass($openClass).css('height', targetHeight);
		} else {
			$target.addClass($openClass).css('height', targetHeight + 40);
		}
	}
};

/* ----------------
	hierarchyAccordion（階層構造のアコーディオン）
	----------------- */
var hierarchyAccordion = function($target) {
	var $targetId = '#' + $target.data('accordion'),
		_targetHeight = $($targetId).find('.hide-target__inner').outerHeight();

	if (!($target).hasClass('js-list_accordion_nest')) {
		if (!$target.hasClass($inactiveClass)) {
			$target.addClass($inactiveClass);
			$($targetId).addClass($inactiveClass).css('height', 0);
		} else {
			$target.removeClass($inactiveClass);
			$($targetId).removeClass($inactiveClass).css('height', _targetHeight);
		}
	} else {
		var $targetParent = $target.parent().parent().parent(),
			_targetParentHeight = $targetParent.find('.hide-target__inner').outerHeight();

		if (!$target.hasClass($inactiveClass)) {
			_targetParentHeight = _targetParentHeight - _targetHeight;
			$target.addClass($inactiveClass);
			$($targetId).addClass($inactiveClass).css('height', 0);
		} else {
			_targetParentHeight = _targetParentHeight + _targetHeight;
			$target.removeClass($inactiveClass);
			$($targetId).removeClass($inactiveClass).css('height', _targetHeight);
		}
		$targetParent.css('height', _targetParentHeight);
	}
};

/* ----------------
	infomationCurrent
	----------------- */
var infomationCurrent = function() {
	var $current = (location.pathname.split('/').length > 2) ? location.pathname.split('/')[2] : '',
		$currentTarget;

	if ($current.indexOf('.html')) {
		$current = $current.replace('.html', '');
	}

	$currentTarget = '#js-information_archive_' + $current;


	if ($current === '' || $current === 'index') {
		$('#js-information_archive_2025').addClass($currentClass);
	} else {
		$($currentTarget).addClass($currentClass);
	}
};

/* ----------------
	pcHeaderCurrent
	----------------- */
var pcHeaderCurrent = function() {
	var $currentId = '#js-current_' + location.pathname.split('/')[1];

	if (location.pathname !== '') {
		$($currentId).addClass($currentClass);
	}
};

/* ----------------
	spHeaderCurrent
	----------------- */
var spHeaderCurrent = function() {
	var pathName = location.pathname,
		dirName, $current, $currentId, $target, $currentParentId, $currentParentTarget;

	if (pathName.indexOf('index.html')) {
		pathName = pathName.replace('index.html', '');
	}

	if ($('#js-header_menu a[href="' + pathName + '"]').length !== 0) {
		if (pathName !== '/') {
			$current = $('#js-header_menu a[href="' + pathName + '"]');
			$current.addClass($currentClass);
			$currentId = $current.parent().parent().parent().attr('id');
			if ($current.hasClass('js-entry_link')) {
				$target = $('[data-accordion="js-accordion1"]');
				hierarchyAccordion($target);
			} else if ($currentId !== 'js-header_menu') {
				$target = $('[data-accordion="' + $currentId + '"]');
				if ($('[data-accordion="' + $currentId + '"]').hasClass('js-list_accordion_nest')) {
					$currentParentId = $target.parent().parent().parent().attr('id');
					$currentParentTarget = $('[data-accordion="' + $currentParentId + '"]');
					hierarchyAccordion($currentParentTarget);
				}
				hierarchyAccordion($target);
			}
		}
	} else {
		dirName = pathName.replace(pathName.split('/')[pathName.split('/').length - 1], '');
		$current = $('#js-header_menu a[href="' + dirName + '"]');
		$current.addClass($currentClass);
		$currentId = $current.parent().parent().parent().attr('id');
		if ($current.hasClass('js-entry_link')) {
			$target = $('[data-accordion="js-accordion1"]');
			hierarchyAccordion($target);
		} else if ($currentId !== 'js-header_menu') {
			$target = $('[data-accordion="' + $currentId + '"]');
			if ($('[data-accordion="' + $currentId + '"]').hasClass('js-list_accordion_nest')) {
				$currentParentId = $target.parent().parent().parent().attr('id');
				$currentParentTarget = $('[data-accordion="' + $currentParentId + '"]');
				hierarchyAccordion($currentParentTarget);
			}
			hierarchyAccordion($target);
		}
	}
};

/* ----------------
	tabOpen
	----------------- */
var tabOpen = function(hashVal) {
	var targetHash  = '#' + hashVal,
		tabKind, $tabLinks, $tabContents;

	if ($(targetHash).hasClass('tab-content')) {
		tabKind = 'tab';
	} else if ($(targetHash).hasClass('tab2-content')) {
		tabKind = 'tab2';
	} else if ($(targetHash).hasClass('tab3-content')) {
		tabKind = 'tab3';
	} else {
		return false;
	}

	$tabLinks = $('.' + tabKind + ' a[href*="#"]');
	$tabContents = (tabKind === 'tab' || tabKind === 'tab2' || tabKind === 'tab3') ? $('.' + tabKind + '-content') : $('.' + tabKind + '-content > *');
	$tabLinks.removeClass($activeClass);
	$('a[href="' + targetHash + '"]').addClass($activeClass);
	$tabContents.not($(targetHash)).removeClass($activeClass);
	$(targetHash).addClass($activeClass);
};

/* ----------------
	minHeightControl
	----------------- */
var minHeightControl = function() {
	$('.js-minheight__controll').each(function() {
		var $this = $(this),
			$thisChild = $this.children(),
			_thisPadding = parseInt($(this).css('padding-top')) + parseInt($(this).css('padding-bottom'));
		$this.css('min-height', $thisChild.outerHeight() + _thisPadding);
	});
};

/* ----------------
	adjustHeight(small)
	----------------- */
var adjustheight = function() {
	var $adjustHeightItem = $('.js-adjustheight__item');
	$adjustHeightItem.css('height', 'auto');
	$adjustHeightItem.each(function() {
		for (var i = 0; i < $adjustHeightItem.length; i += 2) {
			if ($adjustHeightItem.eq(i).outerHeight() < $adjustHeightItem.eq(i + 1).outerHeight()) {
				$adjustHeightItem.eq(i).height($adjustHeightItem.eq(i).outerHeight());
				$adjustHeightItem.eq(i + 1).height($adjustHeightItem.eq(i).outerHeight());
			} else {
				$adjustHeightItem.eq(i).height($adjustHeightItem.eq(i + 1).outerHeight());
				$adjustHeightItem.eq(i + 1).height($adjustHeightItem.eq(i + 1).outerHeight());
			}
		}
	});
};

/* ----------------
	campaignHeightControl
	----------------- */
var campaignHeightControl = function() {
	var $reloader = $('.js-loader'),
		$reloadRoot = $('.js-reload'),
		$reloadBtn = $('.js-reload__btn');

	if ($(window).width() < 768 && !$reloadRoot.eq(0).hasClass('is-after')) {
		var	$hideItems = $reloadRoot.eq(0).find('.campaign'),
			_hideHeight = Math.ceil($hideItems.eq(0).outerHeight() + $hideItems.eq(1).outerHeight() + parseInt($hideItems.eq(1).css('margin-top')) + $hideItems.eq(2).outerHeight() + parseInt($hideItems.eq(2).css('margin-top')) + $hideItems.eq(3).outerHeight() + parseInt($hideItems.eq(3).css('margin-top')));
		$reloadRoot.eq(0).height(_hideHeight);
	} else {
		$reloadRoot.eq(0).css('height', 'auto');
	}
	$reloadBtn.on('click touchstart', function(e) {
		e.preventDefault();
		var	_showHeight = $reloadRoot.eq(0).find('.js-reload__inner').outerHeight();
		$reloadBtn.eq(0).fadeOut(300);
		$reloader.eq(0).fadeIn(300);
		setTimeout(function() {
			$reloader.eq(0).fadeOut(300);
			$reloadRoot.eq(0).height(_showHeight).addClass('is-after');
			$('.reload-btn__wrap').eq(0).hide();
		}, 1000);
	});
};

/* ----------------
	usingSessionStorage
----------------- */
var storage = sessionStorage;

// 絞り込みボタン
$('.js-storage__link').on('click', function(e) {
	e.preventDefault();
	var storage = sessionStorage,
		url = $(this).attr('href').split('#'),
		_trgtAttr = $(this).attr('target'),
		_targetWindow = (_trgtAttr !== undefined && _trgtAttr !== '_self') ? '_blank' : '_self';

	// sessionStorageにハッシュをセット
	if (url[1]) {
		storage.setItem('scrollhash', url[1]);
	}

	// ハッシュ無しのURLに遷移
	window.open(url[0], _targetWindow);
});
$(window).on('load', function() {
	if ($('body').attr('id') !== 'alliance-page') {
		if (storage.length > 0) {
			if (storage.getItem('scrollhash')) {
				var _headerHeight = $('#js-header').outerHeight(),
					_Position = $('#' + storage.getItem('scrollhash')).offset().top;
				if ($(window).width() > 768) {
					_Position -= _headerHeight;
				}
				$('html,body').animate({scrollTop: _Position}, 900);
			}
			sessionStorage.removeItem('scrollhash');
		}
	}
});

// 使えるお店絞り込みリンク
$('[data-filter-store]').on('click', function(e) {
	var val = $(this).data('filter-store');

	if (Array.isArray(val)) {
		var storage = sessionStorage;
		storage.setItem('filter-store', val);
	}
});

// ファビコン
function ins_favicon(URL, rel) {
	var link = document.createElement('link');
	link.type = 'image/x-icon';
	link.rel = rel;
	link.href = URL;

	document.getElementsByTagName('head')[0].appendChild(link);
}

/* ----------------
	コンテンツの高さ並び
	----------------- */
function procedureHeightControll() {
	$('.js-procedure__list').each(function() {
		var $this = $(this),
			$thisText = $this.find('.link-procedure__text'),
			_textHeight = 0;
		$thisText.each(function() {
			if (_textHeight < $(this).height()) {
				_textHeight = $(this).height();
			}
		});
		$thisText.css('height', _textHeight);
	});
}

if ($('.js-procedure__list')[0]) {
	procedureHeightControll();
	$(window).on('resize orientationchange', function() {
		procedureHeightControll();
	});
}


// nanaco登録ページ nanaco番号の確認方法アコーディオン
$(function() {
	if (window.innerWidth <= 768) {
		$('.js-accordion-title').on('click', function() {
			$(this).next().slideToggle(300);
			$(this).toggleClass('open', 300);
		});
		$('.floating').hide();
		$(window).scroll(function() {
			if ($(this).scrollTop() >= 60) {
				$('.floating').fadeIn(300);
			} else {
				$('.floating').fadeOut(300);
			}
		});
	}
});


/* ----------------
	accordion
	シンプルアコーディオン emergency
	----------------- */
$(document).ready(function() {
	$('.js-emergency_accordion').click(function() {
		// 開閉トグル
		var targetContent = $(this).find('.js-hide__target');
		targetContent.slideToggle('fast');

		// クラスの追加と削除
		if ($(this).hasClass('is-open')) {
			$(this).removeClass('is-open').addClass('is-close');
		} else {
			$(this).removeClass('is-close').addClass('is-open');
		}
	});
});
